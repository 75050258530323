<script setup lang="ts">
import DeleteShift from '@app/components/shifts/shifts/actions/DeleteShift.vue'
import SetShiftMembers from '@app/components/shifts/shifts/actions/SetShiftMembers.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { Shift } from '@app/types/shifts'
import { Bars4Icon, UserGroupIcon } from '@heroicons/vue/20/solid'
import ShiftDetails from './ShiftDetails.vue'
import ShiftMembers from './ShiftMembers.vue'
import EditShift from './actions/EditShift.vue'
import RefreshBriggsWalkerShift from './actions/RefreshBriggsWalkerShift.vue'

const { shift } = defineProps<{
    shift: Shift
}>()

const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="shift" :dismissable="true" @close="emit('close')" class="md:max-w-[26rem]">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle> Shift ID: {{ shift.id }}</DetailCardTitle>
            <ShiftDetails :shift />
        </div>
        <template #actions>
            <SetShiftMembers :shift />
            <RefreshBriggsWalkerShift :shift />
            <EditShift :shift />
            <DeleteShift :shift />
        </template>
        <template #tabs>
            <DetailCardTab tab="shift_members" :label="$t('shifts.members')">
                <template #icon>
                    <UserGroupIcon />
                </template>
                <ShiftMembers :shiftMembers="shift.shiftMembers" />
            </DetailCardTab>
            <DetailCardTab tab="activity" :label="$t('activities.label')">
                <template #icon>
                    <Bars4Icon />
                </template>
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
