<script setup lang="ts">
import ShiftMemberListItem from '@app/components/shifts/shifts/ShiftMemberListItem.vue'
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Shift, ShiftMember } from '@app/types/shifts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{
    shift: Shift
    shiftMembers: Array<ShiftMember>
}>()

const emit = defineEmits(['cancel', 'saved'])

const { dbTranslate } = useLocale()

const form = useForm({
    availabilities: props.shiftMembers.map((member) => member.shift_availability_id),
})

const toggleMemberSelection = (availabilityId: number) => {
    const index = form.availabilities.indexOf(availabilityId)
    if (index === -1) {
        form.availabilities.push(availabilityId)
    } else {
        form.availabilities.splice(index, 1)
    }
}

const setShiftMembers = () => {
    form.post(route('shifts.shift.set-members', { id: props.shift.id, shift_availability_ids: form.availabilities }), {
        onSuccess: () => {
            emit('saved')
        },
    })
}
</script>

<template>
    <Form :form="form">
        <FormSection title="Set shift members">
            <FormField class="col-span-12" prop="availabilities">
                <ShiftMemberListItem v-for="member in props.shiftMembers" :key="member.id" :member="member">
                    <Checkbox
                        :modelValue="form.availabilities.includes(member.shift_availability_id)"
                        @change="toggleMemberSelection(member.shift_availability_id)"
                        class="ml-auto"
                    />
                </ShiftMemberListItem>
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('cancel')" />
            <SaveButton :disabled="form.processing" @click="setShiftMembers" />
        </FormActions>
    </Form>
</template>
