<script setup lang="ts">
import ShiftMemberSetForm from '@app/components/shifts/shifts/forms/ShiftMemberSetForm.vue'
import ShiftMemberListItem from '@app/components/shifts/shifts/ShiftMemberListItem.vue'
import Button from '@app/components/ui/button/Button.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Shift, ShiftMember } from '@app/types/shifts'
import { PencilSquareIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'

const props = defineProps<{
    shift: Shift
    shiftMembers: Array<ShiftMember>
}>()

const { dbTranslate } = useLocale()

const isEditing = ref(false)

const toggleEditing = () => {
    isEditing.value = !isEditing.value
}

const handleFormSaved = () => {
    isEditing.value = false
}
</script>

<template>
    <div class="flex flex-col gap-4 py-4">
        <ShiftMemberSetForm v-if="isEditing" :shift="props.shift" :shiftMembers="props.shiftMembers" @cancel="toggleEditing" @saved="handleFormSaved" />
        <div v-else v-for="member in props.shiftMembers" :key="member.id">
            <ShiftMemberListItem :member="member" />
        </div>
        <Button
            color="white"
            size="xs"
            v-show="!isEditing && props.shiftMembers.length != 0"
            class="ml-auto inline-block rounded-md bg-primary-500 px-4 py-2 text-white hover:bg-primary-600"
            @click="toggleEditing"
        >
            <PencilSquareIcon class="h-4 w-4 text-zinc-600" />
            Edit shift members
        </Button>
        <div v-if="props.shiftMembers.length === 0" class="text-sm text-gray-500">There are no members planned for this shift.</div>
    </div>
</template>
