<script setup lang="ts">
import type { ShiftAvailability } from '@app/types/shifts'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { format } from 'date-fns'
import { computed } from 'vue'

const {
    availability,
    mode = 'normal',
    disabled = false,
    selected = false,
} = defineProps<{
    availability: ShiftAvailability
    mode?: 'normal' | 'select'
    disabled?: boolean // only for select mode
    selected?: boolean // only for select mode
}>()

const emit = defineEmits(['availability:clicked', 'shift:clicked', 'select'])

const from = computed(() => format(new Date(availability.from), 'HH:mm'))
const to = computed(() => format(new Date(availability.to), 'HH:mm'))

const title = () => {
    return availability.work_type_slug === 'sales-agent'
        ? availability.client_project_name + ' / ' + availability.location_type_slug
        : availability.client_project_name
}

function handleClick() {
    if (mode === 'normal') {
        if (availability.shift) {
            emit('shift:clicked', availability.shift.id)
        } else {
            emit('availability:clicked', availability.id)
        }
    } else if (!disabled) {
        emit('select')
    }
}
</script>

<template>
    <div
        class="relative flex w-full flex-1 overflow-hidden p-1 transition-all duration-300"
        :class="[mode === 'normal' || !disabled ? 'cursor-pointer hover:brightness-90' : '']"
        @click="handleClick"
    >
        <div
            class="group flex w-full items-start gap-2 whitespace-nowrap rounded-lg px-1 py-1 text-xs font-medium uppercase scrollbar-hide"
            :class="[
                'bg-' + availability.client_color_code + '-50 text-' + availability.client_color_code + '-700',
                mode === 'select' && disabled ? 'grayscale' : '',
                mode === 'select' && selected ? 'outline outline-2 outline-primary-400' : '',
            ]"
            :title="availability.client_project_name + ' - ' + availability.location_type_name + ' - ' + from + ' to ' + to"
        >
            <div class="left-2 h-full w-[5px] flex-shrink-0 rounded" :class="availability.shift ? ['bg-' + availability.client_color_code + '-500'] : ''"></div>
            <div class="flex flex-grow flex-col">
                <div class="flex h-4 w-full items-center justify-between">
                    <time class="flex flex-grow flex-col font-light">{{ from }} - {{ to }}</time>
                    <ExclamationCircleIcon v-if="availability.shift_member_status === 'should-have-started'" class="ml-auto h-5 w-5" />
                </div>
                <p class="text-ellipsis">{{ title() }}</p>
                <p class="text-ellipsis font-light italic">{{ availability.branch_name }}</p>
            </div>
        </div>
    </div>
</template>
