<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { ShiftAvailability } from '@app/types/shifts'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import EditAvailabilityForm from '../forms/EditAvailabilityForm.vue'

const { availability } = defineProps<{ availability: ShiftAvailability }>()
const { can } = useUserCan()
const show = computed(() => can('shifts.availability.update'))

const { toggle } = useActiveButton()
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.edit')" @click="toggle(EditAvailabilityForm)">
        <PencilIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
