<script setup lang="ts">
import AvailabilityCard from '@app/components/shifts/availabilities/AvailabilityCard.vue'
import PersonalAvailabilityForm from '@app/components/shifts/availabilities/forms/PersonalAvailabilityForm.vue'
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import ShiftCard from '@app/components/shifts/shifts/ShiftCard.vue'
import Button from '@app/components/ui/button/Button.vue'
import Body from '@app/components/ui/calendar/body/Body.vue'
import TimeGrid from '@app/components/ui/calendar/body/TimeGrid.vue'
import TimeSlot from '@app/components/ui/calendar/body/TimeSlot.vue'
import Calendar from '@app/components/ui/calendar/Calendar.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import type { Shift, ShiftAvailability, ShiftTemplate } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { eachDayOfInterval, endOfWeek, isPast, startOfWeek } from 'date-fns'
import { computed, ref } from 'vue'

const { selectedShift, selectedAvailability } = defineProps<{
    availabilities: ShiftAvailability[]
    userShiftTemplates: ShiftTemplate[]
    selectedShift: Shift | null
    selectedAvailability: ShiftAvailability | null
}>()

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['availabilities'] })

const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

const isPastWeek = computed(() => isPast(endOfWeek(selectedDay.value, { weekStartsOn: 1 })))
const openAvailabilityForm = ref(false)

function handleAvailabilityClick(id: number) {
    if (selectedAvailability?.id === id) {
        applyFilter({ selected_shift: null, selected_availability: null }, { only: ['selectedShift', 'selectedAvailability'] })
    } else {
        applyFilter({ selected_shift: null, selected_availability: id }, { only: ['selectedShift', 'selectedAvailability'] })
    }
}

function handleShiftClick(id: number) {
    if (selectedShift?.id === id) {
        applyFilter({ selected_shift: null, selected_availability: null }, { only: ['selectedShift', 'selectedAvailability'] })
    } else {
        applyFilter({ selected_shift: id, selected_availability: null }, { only: ['selectedShift', 'selectedAvailability'] })
    }
}
</script>

<template>
    <ModalLayout :show="openAvailabilityForm" @close="openAvailabilityForm = false">
        <PersonalAvailabilityForm :date="selectedDay" :user-shift-templates="userShiftTemplates" @close="openAvailabilityForm = false" />
    </ModalLayout>
    <div class="mt-2 flex gap-2">
        <Calendar :days class="flex w-full flex-col transition-all duration-500">
            <Header :date="selectedDay">
                <Button :disabled="isPastWeek" @click="openAvailabilityForm = true">
                    {{ $t('calendar.availability.add') }}
                </Button>
                <Divider />
                <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" />
            </Header>

            <Body>
                <TimeGrid :start="10" :end="21">
                    <TimeSlot
                        v-for="availability in availabilities"
                        :from="availability.shift?.start ?? availability.from"
                        :to="availability.shift?.end ?? availability.to"
                    >
                        <ShiftAvailabilityEvent :availability @availability:clicked="handleAvailabilityClick" @shift:clicked="handleShiftClick" />
                    </TimeSlot>
                </TimeGrid>
            </Body>
        </Calendar>
        <Transition name="slide-fade" mode="out-in">
            <template v-if="selectedAvailability || selectedShift">
                <AvailabilityCard
                    v-if="selectedAvailability"
                    class="md:min-w-[29rem] lg:w-2/3"
                    @close="handleAvailabilityClick(selectedAvailability.id)"
                    :availability="selectedAvailability"
                />

                <ShiftCard v-else-if="selectedShift" @close="handleShiftClick(selectedShift.id)" :shift="selectedShift" />
            </template>
        </Transition>
    </div>
</template>
