<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMember } from '@app/types/shifts'

const props = defineProps<{
    member: ShiftMember
}>()

const { dbTranslate } = useLocale()
</script>

<template>
    <div class="flex w-full items-center rounded-md border border-zinc-100 p-4">
        <div class="flex items-center gap-3">
            <div class="rounded-full bg-primary-100 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                <img
                    class="inline-block h-9 w-9 rounded-full"
                    :src="member.shift_availability.userable.profile_photo_url"
                    :alt="member.shift_availability.userable.full_name"
                />
            </div>
            <div>
                <p class="text-sm font-medium leading-5 text-zinc-800">
                    {{ member.shift_availability.userable.full_name }}
                </p>
                <p class="text-xs text-zinc-500">
                    {{ dbTranslate(member.shift_availability.userable.job_title.name) }}
                </p>
            </div>
        </div>
        <slot></slot>
    </div>
</template>
